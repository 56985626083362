const types = [
  {

    // IIC 75

    'form': 'IIC',
    'image': 'iic_1-2.png',
    'anpassbereich': '75',
    'nfmi': false,
    'bedienelemente': false,
    'mikrofone': false,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '10er Batterie'],
    'sprite': '01_sprite.jpg',
    'class': 'IIC'
  },


  // IIC 85
  {
    'form': 'IIC',
    'image': 'iic_1-2.png',
    'anpassbereich': '85',
    'nfmi': false,
    'bedienelemente': false,
    'mikrofone': false,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '10er Batterie'],
    'sprite': '02_sprite.jpg',
    'class': 'IIC'
  },


  // CIC 75
  {
    'form': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '75',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': false,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '10er Batterie', 'NFMI', 'Taster'],
    'sprite': '03_sprite.jpg',
    'class': 'CIC'
  },


  // CIC 85
  {
    'form': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': false,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '10er Batterie', 'NFMI', 'Taster'],
    'sprite': '04_sprite.jpg',
    'class': 'CIC'
  },

  // Kanal 75

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_5-8.png',
    'anpassbereich': '75',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI'],
    'sprite': '05_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 85
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_5-8.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI'],
    'sprite': '06_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 90
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_5-8.png',
    'anpassbereich': '90',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI'],
    'sprite': '07_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 100
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_5-8.png',
    'anpassbereich': '100',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI'],
    'sprite': '08_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 75 GHZ

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '75',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz'],
    'sprite': '09_sprite.jpg',
    'class': 'Kanal'
  },



  // Kanal 85 GHZ
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz'],
    'sprite': '10_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 90 GHZ
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '90',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz'],
    'sprite': '11_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 100 GHZ
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '100',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz'],
    'sprite': '12_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 75 GHZ Bedienelemente
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '75',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', '2,4 GHz'],
    'sprite': '13_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 85 GHZ Bedienelemente
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', '2,4 GHz'],
    'sprite': '14_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 90 GHZ Bedienelemente
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '90',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', '2,4 GHz'],
    'sprite': '15_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 100 GHZ Bedienelemente
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '100',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', '2,4 GHz'],
    'sprite': '16_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 75 Spule Bedienelemente
  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '75',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': false,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', 'T-Spule'],
    'sprite': '13_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 85 Spule Bedienelemente

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': false,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', 'T-Spule'],
    'sprite': '14_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 90 Spule Bedienelemente

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '90',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': false,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', 'T-Spule'],
    'sprite': '15_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 100 Spule Bedienelemente

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '100',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': false,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', 'T-Spule'],
    'sprite': '16_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 75 Spule 

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '75',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': false,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'T-Spule'],
    'sprite': '09_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 85 Spule 

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': false,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'T-Spule'],
    'sprite': '10_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 90 Spule 

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '90',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': false,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'T-Spule'],
    'sprite': '11_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 100 Spule 

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '100',
    'nfmi': true,
    'bedienelemente': false,
    'mikrofone': true,
    'ghz': false,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'T-Spule'],
    'sprite': '12_sprite.jpg',
    'class': 'Kanal'
  },


  // Kanal 75 Bedienelemente 

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '75',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente'],
    'sprite': '13_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 85 Bedienelemente 

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente'],
    'sprite': '14_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 90 Bedienelemente 

  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '90',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente'],
    'sprite': '15_sprite.jpg',
    'class': 'Kanal'
  },

  // Kanal 100 Bedienelemente 


  {
    'form': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '100',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': false,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '312er Batterie', '2 Mikrofone', 'NFMI', 'Bedienelemente', 'Gehörgang: eher groß'],
    'sprite': '16_sprite.jpg',
    'class': 'Kanal'
  },


  // Concha 85


  {
    'form': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '13er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz', 'Taster & VC-Rad', 'Gehörgang: eher groß'],
    'sprite': '17_sprite.jpg',
    'class': 'Concha'
  },

  // Concha 100


  {
    'form': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': true,
    'tSpule': false,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '13er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz', 'Taster & VC-Rad', 'Gehörgang: eher groß'],
    'sprite': '18_sprite.jpg',
    'class': 'Concha'
  },

  // Concha T-Spule 85
  {
    'form': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': true,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '13er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz', 'Taster & VC-Rad', 'T-Spule'],
    'sprite': '19_sprite.jpg',
    'class': 'Concha'
  },



  // Concha T-Spule 100

  {
    'form': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100',
    'nfmi': true,
    'bedienelemente': true,
    'mikrofone': true,
    'ghz': true,
    'tSpule': true,
    'baseConfig': ['Opn Technologie', 'OpenSound Navigator™', '13er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz', 'Taster & VC-Rad', 'T-Spule'],
    'sprite': '20_sprite.jpg',
    'class': 'Concha'
  },

];

let result = {
  'form': '',
  'image': '',
  'anpassbereich': '',
  'nfmi': false,
  'bedienelemente': false,
  'mikrofone': false,
  'ghz': false,
  'tSpule': false,
  'baseConfig': [],
  'earSize': '',
  'sprite': ''
};





