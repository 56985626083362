// Variablen

const device = $('#config01 > .choose'),
  firstStep = $('#config01'),
  secondStep = $('#config02'),
  thirdStep = $('#config03'),
  fourthStep = $('#config04'),
  breadcrumbStep1 = $('#bc1'),
  breadcrumbStep2 = $('#bc2'),
  breadcrumbStep3 = $('#bc3'),
  breadcrumbStep4 = $('#bc4'),
  alertMsg = $('.alert'),
  info = $('.info'),
  step = $('.step'),
  previewHeading = $('.previewHeading'),
  previewImage = $('.previewImage'),
  ausstattung = $('#ausstattung'),
  anpassbereich = $('#anpassbereichCB .customInput'),
  anpass = $('#configAnpass'),
  nfmi = $('#configNFMI'),
  bedien = $('#configBedien'),
  mic = $('#configMic'),
  ghz = $('#configGhz'),
  spule = $('#configTspule'),
  configSection = $('.configSection'),
  customCheckbox = $('.customCheckbox'),
  next = $('#next'),
  spriteImage = $('#hdoToggle'),
  hdoOverlay = $('.hdoOverlay'),
  nextActive = $('#next.active');;


// Weiter Button aktivieren

anpassbereich.on('change', function () {
  result.anpassbereich = this.value;
  next.addClass('active');
  $('#bc3').addClass('activated');


})

// Weiter Button Funktion 

next.on('click', function () {
  if ($(this).hasClass('active')) {
    breadcrumbLink.removeClass('active');

    $('#bc3').addClass('active').addClass('activated');
    step.removeClass('active');
    $('#config03').addClass('active');

  }
})

// Checkboxen abwählen
function uncheck() {
  var uncheck = $('input[type="radio"]');
  for (var i = 0; i < uncheck.length; i++) {
    uncheck[i].checked = 0;
  }
  result.anpassbereich = '';

}


// IIC

function linkIIC() {

  resetForm();

  anpass.addClass('active');

  step.removeClass('active');
  secondStep.addClass('active');
  popup.removeClass('active');
  uncheck();
  $('.noCIC').show();

  customCheckbox.removeClass('preChecked').removeClass('preDefined');

  result.form = "IIC";
  result.image = "IIC.png";
  result.nfmi = false;
  result.mikrofone = false;
  result.bedienelemente = false;
  result.ghz = false;
  result.tSpule = false;
  result.baseConfig = ['Opn Technologie', '10er Batterie'];

  $('.noCIC').hide();

  previewHeading.html('<span>IIC</span>');
  previewImage.html('<img src="assets/img/IIC.png" alt="Oticon Opn" class="IIC">');
  ausstattung.html('<li>Oticon Opn Technologie</li><li>OpenSound Navigator™</li><li>10er Batterie</li>');

  breadcrumbStep1.removeClass('active').addClass('activated').next().addClass('active').addClass('activated');
  showResult();
}

// CIC

function linkCIC() {

  resetForm();

  anpass.addClass('active');
  nfmi.addClass('active');
  bedien.addClass('active');
  $('#cbNFMI').attr('disabled', true);
  $('#cbBedien').attr('disabled', true);
  $('#configNFMI .customCheckbox').addClass("preChecked");
  $('#configBedien .customCheckbox').addClass("preChecked");


  var cbNFMI = $('#cbNFMI');
  var cbBedien = $('#cbBedien');
  for (var i = 0; i < cbNFMI.length; i++) {
    cbNFMI[i].checked = true;
  }
  for (var i = 0; i < cbBedien.length; i++) {
    cbBedien[i].checked = true;
  }


  step.removeClass('active');
  secondStep.addClass('active');
  popup.removeClass('active');
  uncheck();

  result.form = "CIC";
  result.image = "CIC.png";
  result.nfmi = true;
  result.mikrofone = false;
  result.bedienelemente = true;
  result.ghz = false;
  result.tSpule = false;
  result.baseConfig = ['Opn Technologie', '10er Batterie', 'NFMI', 'Taster'];

  $('.noCIC').hide();

  previewHeading.html('<span>CIC</span>');
  previewImage.html('<img src="assets/img/CIC.png" alt="Oticon Opn" class="CIC">');
  ausstattung.html('<li>Oticon Opn Technologie</li><li>OpenSound Navigator™</li><li>10er Batterie</li><li>NFMI</li><li>Taster</li>');

  breadcrumbStep1.removeClass('active').addClass('activated').next().addClass('active').addClass('activated');

  showResult();
}


// Kanal

function linkKanal() {

  resetForm();

  configSection.addClass('active');
  ghz.addClass('kanal');
  spule.addClass('kanal');

  step.removeClass('active');
  secondStep.addClass('active');
  popup.removeClass('active');
  uncheck();

  $('input[type="checkbox"]').attr('disabled', false);

  $('#cbNFMI').attr('checked', true).attr('disabled', true);
  $('#cbMic').attr('checked', true).attr('disabled', true);
  $('#configNFMI .customCheckbox').addClass("preDefined");
  $('#configMic .customCheckbox').addClass("preDefined");


  var cbNFMI = $('#cbNFMI');
  var cbMic = $('#cbMic');
  for (var i = 0; i < cbNFMI.length; i++) {
    cbNFMI[i].checked = true;
  }
  for (var i = 0; i < cbMic.length; i++) {
    cbMic[i].checked = true;
  }

  result.form = "Kanal / Halb-Concha";
  result.image = "ITC_Kanal.png";
  result.nfmi = true;
  result.mikrofone = true;
  result.bedienelemente = false;
  result.ghz = false;
  result.tSpule = false;
  result.baseConfig = ['Opn Technologie', '312er Batterie', '2 Mikrofone', 'NFMI'];

  previewHeading.html('<span>Kanal / Halb-Concha</span>');
  previewImage.html('<img src="assets/img/ITC_Kanal.png" alt="Oticon Opn" class="Kanal">');
  ausstattung.html('<li>Oticon Opn Technologie</li><li>OpenSound Navigator™</li><li>312er Batterie</li><li>2 Mikrofone</li><li>NFMI</li>');

  breadcrumbStep1.removeClass('active').addClass('activated').next().addClass('active').addClass('activated');
  showResult();
}

// Concha

function linkConcha() {

  resetForm();

  configSection.addClass('active');
  step.removeClass('active');
  secondStep.addClass('active');
  popup.removeClass('active');
  uncheck();
  $('.noConcha').hide();

  $('input[type="checkbox"]').attr('disabled', false);

  $('#cbNFMI').attr('disabled', true);
  $('#cbMic').attr('disabled', true);
  $('#cbBedien').attr('disabled', true);
  $('#cbGhz').attr('disabled', true);
  $('#configNFMI .customCheckbox').addClass("preDefined");
  $('#configMic .customCheckbox').addClass("preDefined");
  $('#configBedien .customCheckbox').addClass("preChecked");
  $('#configGhz .customCheckbox').addClass("preChecked");

  var cbNFMI = $('#cbNFMI');
  var cbMic = $('#cbMic');
  var cbBedien = $('#cbBedien');
  var cbGhz = $('#cbGhz');

  for (var i = 0; i < cbNFMI.length; i++) {
    cbNFMI[i].checked = true;
  }
  for (var i = 0; i < cbMic.length; i++) {
    cbMic[i].checked = true;
  }
  for (var i = 0; i < cbBedien.length; i++) {
    cbBedien[i].checked = true;
  }
  for (var i = 0; i < cbGhz.length; i++) {
    cbGhz[i].checked = true;
  }


  result.form = "Concha";
  result.image = "ITC_Concha.png";
  result.nfmi = true;
  result.mikrofone = true;
  result.bedienelemente = true;
  result.ghz = true;
  result.tSpule = false;
  result.baseConfig = ['Opn Technologie', '312er Batterie', '2 Mikrofone', 'NFMI', '2,4 GHz', 'Taster & VC-Rad'];

  previewHeading.html('<span>Concha</span>');
  previewImage.html('<img src="assets/img/ITC_Concha.png" alt="Oticon Opn" class="Concha">');
  ausstattung.html('<li>Oticon Opn Technologie</li><li>OpenSound Navigator™</li><li>312er Batterie</li><li>2 Mikrofone</li><li>NFMI</li><li>2,4 GHz</li><li>Taster & VC-Rad</li>');

  breadcrumbStep1.removeClass('active').addClass('activated').next().addClass('active').addClass('activated');

  showResult();
}

// Checkboxen zurücksetzen

function resetForm() {

  reset();
  next.removeClass('active');
  customCheckbox.removeClass('preChecked');
  customCheckbox.removeClass('preDefined');
  const checkboxArray = $('input[type="checkbox"]');
  $('.noCIC').show();
  $('.noConcha').show();
  for (var i = 0; i < checkboxArray.length; i++) {
    checkboxArray[i].checked = false;
  }
  configSection.removeClass('kanal').removeClass('active');
}

// Switch 2,4 GHz / T-Spule

$('#cbGhz').on('change', function () {
  if (ghz.hasClass('kanal'))
    spule.toggleClass('active');
  showResult();
})

$('#cbSpule').on('change', function () {
  if (spule.hasClass('kanal')) {
    ghz.toggleClass('active');
    showResult();

  }
})

// Checkbox Werte an Result übergeben

const cbAll = $('input[type="checkbox"]');

cbAll.on('change', function () {
  let resultValue = this.dataset.value;
  if (this.checked) {
    result[resultValue] = true;
  } else {
    result[resultValue] = false;
  }
  showResult();

})

// Ohrgröße

$('.ear').on('click', function () {
  let earValue = this.dataset.size;
  let earOutput = this.dataset.value;
  result.earSize = earValue;
  result.earValue = earOutput;
  step.removeClass('active');
  fourthStep.addClass('active');

  breadcrumbLink.removeClass('active');
  $('#bc4').addClass('active').addClass('activated');

  showResult();

})

// Result Content
function showResult() {
  var list = '';

  for (var i = 0; i < types.length; i++) {
    if (types[i].form == result.form &&
      types[i].anpassbereich == result.anpassbereich &&
      types[i].nfmi == result.nfmi &&
      types[i].bedienelemente == result.bedienelemente &&
      types[i].mikrofone == result.mikrofone &&
      types[i].ghz == result.ghz &&
      types[i].tSpule == result.tSpule) {

      for (var j = 0; j < types[i].baseConfig.length; j++) {
        list += '<li>' + types[i].baseConfig[j] + '</li>';
      }
      list += `<li>Anpass-Bereich: ${types[i].anpassbereich}</li>`
      list += `<li>${result.earValue}</li>`

      if(result.earSize !== ''){var earPathreg = result.earSize} else{var earPathreg = 'small'}


      let outputResult = `
            <div class="spriteContainer col-1">
              <div class="spriteImage" id="hdoToggle" style="background: url(assets/img/${earPathreg}/${types[i].sprite}) 50% center no-repeat"></div>
              <div class="spriteImage hdoOverlay" style="background: url(assets/img/${earPathreg}/hdo_sprite.jpg) 50% center no-repeat"></div>
              <div class="spriteSelection">
                <input type="range" value="50" step="50" min="0" max="100" id="range">
              </div>
              
            </div>
            <div class="resultContent col-2">
              <div class="resultHeader">${types[i].form}<div id="secretButton" onclick="$('.hdoOverlay').toggleClass('active');"></div></div>
              <div class="resultBody">
              <div class="resultList col-1">
                <h3>Konfiguration</h3>
                  <ul>
                    ${list}
                  </ul>
                  <div class="sternchen" id="resultHinweis"><strong>Hinweis: </strong>Das Foto links zeigt das Modell, das der gewählten Konfiguration am nächsten kommt.</div>

              </div>
              <div class="resultImage col-1">
                <img src="assets/img/${types[i].image}" class="${types[i].class}" alt="Oticon Opn">
                <p class="sternchen">Modellbeispiel</p>
              </div>
              </div>
              <div id="print"onclick="printPage()"><img alt="Seite Ausdrucken" src='assets/img/printer.svg'>Drucken</div>

            </div>
          `;
      let outputPrintBauform = `
      <div class="resultHeader">${types[i].form}</div>
      <div class="resultBody">
      <div class="spriteImage"> <img src="assets/img/${earPathreg}/${types[i].sprite}">></div>

      <div class="resultList col-1">
        <h3>Konfiguration</h3>
          <ul>
            ${list}
          </ul>
      </div>
      <div class="resultImage col-1">
        <img src="assets/img/${types[i].image}" class="${types[i].class}" alt="Oticon Opn">
      </div>
      </div>
          `;
      $('#ergebnis').html(outputResult)
      $('#printPage2 .popupContent').html(outputPrintBauform)

    }
  }
}

// Range Slider Sprite
$(document).on('input change', '#range', function () {
  let spriteValue = this.value + '%';
  $('.spriteImage').css({
    'background-position-x': spriteValue
  });
  console.log(spriteValue)

});

// Reset Ergebnis Output

function clearResult() {
  $('#ergebnis').html('');
}

// HdO Ergebnis Überlagern

function toggleHdo() {
  hdoOverlay.toggleClass('active');
}


//links in FullScreen Mode
function fullscreen() {
  $('a').click(function () {
    if (!$(this).hasClass('noeffect')) {
      window.location = $(this).attr('href');
      return false;
    }
  });
}

fullscreen();