const popupHinweis = {
  info: {
    heading: 'Willkommen beim IdO-Konfigurator für Oticon Opn™',
    content: `
    <p>Im-Ohr-Hörsysteme werden maßangefertigt. Die Anatomie des Kundenohrs und die Ausstattung des Gerätes bestimmen den Sitz im Ohr. Mit dem IdO-Konfigurator können Sie und Ihr Kunde eine ungefähre Vorstellung vom Sitz des Gerätes im Ohr bekommen. <br><br>
    In diesem Beratungswerkzeug sind 20 typische Geräte-Konfigurationen für drei verschiedene Gehörgangs- bzw. Ohrgrößen hinterlegt.
    </p>
    <br><br>
    <p><strong>In vier einfachen Schritten zur gewünschten Konfiguration:</strong></p>
    <ol>
    <li><span class="numbers">1</span><span class="strongMagenta">Bauform wählen: </span><br>Sie entscheiden sich für eine Bauform.</li>
    <li><span class="numbers">2</span><span class="strongMagenta">IdO konfigurieren: </span><br>Sie entscheiden sich für einen Anpass-Bereich. Wenn gewünscht, wählen Sie hier weitere Funktionen. Die Felder für Funktionen, die für diese gewählte Bauform nicht zur Verfügung stehen, sind gegraut. Die Farben der Haken, die gesetzt werden können, haben folgende Bedeutungen:<br><br>
    <div class="customCheckbox"></div><p><span class="strongMagenta">Magenta:</span> Ihre Auswahl</p><br>
    <div class="customCheckbox preChecked"></div><p><span class="strongBlue">Blau:</span> Vorauswahl für den Konfigurator (diese Funktion ist sonst optional)</p><br>
    <div class="customCheckbox preDefined"></div><p><span class="strongGrey">Grau:</span> Standardausstattung</p><br><br>
    <p><strong>Weitere Symbole unter Schritt 2:</strong></p><br><br>

    <img class="info"src="assets/img/info.svg" alt="Info" width="15" height="15" style="margin-right: 10px"><p>Hier finden Sie kurze Informationen zu den Funktionen.</p><br><br>
    <img class="alert" src="assets/img/alert.svg" alt="Hinweis" width="15" height="15" style="margin-right: 10px"><p>Dieses Symbol erscheint, wenn eine Kombination nicht verfügbar ist. </p><br><br>
    <button id="next" class="active">weiter</button><p>Das "Weiter"-Feld erscheint, wenn alle Pflichtfelder ausgewählt worden sind und Sie die Ohrgröße wählen können.</p>
    
    
    </li>
    <li><span class="numbers">3</span><span class="strongMagenta">Ohrgröße auswählen:</span><br> Hier schätzen Sie subjektiv die Größe des Kundenohres ein. Wie groß sind Gehörgang und Concha? Ist das Ohr eher klein und Sie vermuten, dass ein IIC nicht möglich ist? Ist das Ohr eher mittelgroß und Sie erwarten, dass ein IIC angefertigt werden kann? Oder ist das Ohr sogar so groß, dass Sie davon ausgehen, dass auch ein ITC/HS sehr unauffällig im Ohr sitzen wird.
    In dem Konfigurator ist das eher kleine Ohr das Ohr eines weiblichen Models, während die Models des mittleren und großen Ohres männlich sind.
    </li>
    <li><span class="numbers">4</span><span class="strongMagenta">Ergebnis ansehen:</span><br> Hier sehen Sie links das konfigurierte IdO im Ohr. Mit dem Schieberegler können Sie zwischen drei Perspektiven wechseln. Auf dieser Seite finden Sie die konfigurierte Ausstattung sowie ein Modellbild der Geräte-Kategorie. <br><br>
    <strong>Tipp:</strong> Sie möchten Ihrem Kunden ein Ex-Hörer Mini Hörsystem zum Vergleich zeigen? Dann klicken Sie auf den Magenta Balken, in dem der Modellname steht, am rechten Rand und das Ohr wird mit einem Ex-Hörer Mini angezeigt. Erneutes Klicken wechselt wieder zu den IdO-Systemen. 
    
    </li>
    <img src="assets/img/klickbereich.jpg" class="popupImageFW">
    <ol><br>
    <h2>Vollständige Auswahl<h2>
    <p style="display: block"> Der Konfigurator zeigt eine typische Auswahl an IdO-Systemen. Es wurden Modelle vorkonfiguriert, bei denen die Ausstattung die Gerätegröße beeinflusst. Für Ihre Anpassung im Fachgeschäft haben Sie noch weitere Kombinationsmöglichkeiten, die in der folgenden Tabelle vollständig aufgeführt sind.

    </p>
    <img src="assets/img/ausstattung.png" alt="Ausstattungsvarianten"  class="popupImageFW">
 

    `
  },
  info2: {
    heading: 'Willkommen beim Modus „Funktionen" des IdO-Konfigurators',
    content: `
    <p>Im-Ohr-Hörsysteme werden maßangefertigt. Die Ausstattung des Gerätes und die Anatomie des Kundenohrs bestimmen den Sitz im Ohr. 
    Während Sie in dem Modus „Bauform” zuerst eine Bauform und dafür verfügbare Ausstattungen wählen, starten Sie im Modus „Funktionen“ mit den gewünschten Ausstattungen und Funktionen, die der Kunde wünscht. Das könnten z.B. Konnektivität und eine manuelle Bedienung der Lautstärke sein. Der IdO-Konfigurator zeigt Ihnen dann im Modus „Funktionen” nur die Bauformen an, die mit den gewünschten Optionen bestellbar sind. 
    Eine Übersicht, wie Bauform, Ausstattung und Funktionen zusammenhängen, finden Sie in der nachfolgenden Tabelle:  
    
    <img src="assets/img/ausstattung.png" alt="Ausstattungsvarianten"  class="popupImageFW"> 

    </p>
    <br><br>
    <p><strong>In vier einfachen Schritten zur gewünschten Konfiguration:</strong></p>
    <ol>
    <li><span class="numbers">1</span><span class="strongMagenta">Hörvermögen: </span><br>Sie geben an, ob die Versorgung monaural oder beidohrig sein wird und welchen Anpass-Bereich Sie benötigen, um die Hörminderung des Kunden zu kompensieren. <br><br>
    <strong>Hinweis:</strong> Der IdO-Konfigurator geht im Falle einer beidohrigen Versorgung der Einfachheit halber immer davon aus, dass für beide Ohren dieselbe Bauform angefertigt wird. 

    </li>
    <li><span class="numbers">2</span><span class="strongMagenta">Kundenwünsche: </span><br>Sie entscheiden sich für die Ausstattung des IdO-Systems. Idealerweise starten Sie die Auswahl von oben nach unten, da die oberen Funktionen untere Funktionen bedingen. D.h. Funktionen, die mit einer gewählten zusammen hängen, werden automatisch angezeigt. Sie können aber genauso gut z.B. mit der Auswahl der audiologischen Funktionen starten. Ihre gewählte Ausstattung wird links in der Leiste unter „Ausstattung“ angezeigt. Nach jeder hinzu- oder abgewählten Funktion sehen Sie links oben und ganz unten alle Bauform(en), die wie gewünscht konfigurierbar und bestellbar sind. Am Ende von diesem 2. Schritt müssen Sie eine der möglichen Bauformen auswählen. Das tun Sie, indem Sie auf das gewünschte Modell klicken – entweder oben links oder ganz unten. Unter <img class="info"src="assets/img/info.svg" alt="Info" width="15" height="15" style="margin: 0 5px"> finden Sie jeweils kurze Informationen zu der Funktion.<br><br>


    
    </li>
    <li><span class="numbers">3</span><span class="strongMagenta">Ohrgröße auswählen:</span><br> Hier schätzen Sie subjektiv die Größe des Kundenohres ein. Wie groß sind Gehörgang und Concha? Ist das Ohr eher klein und Sie vermuten, dass ein IIC nicht möglich ist? Ist das Ohr eher mittelgroß und Sie erwarten, dass ein IIC angefertigt werden kann? Oder ist das Ohr sogar so groß, dass Sie davon ausgehen, dass auch ein ITC/HS sehr unauffällig im Ohr sitzen wird.<br><br>
    <strong>Hinweis:</strong> In dem Konfigurator ist das eher kleine Ohr das Ohr eines weiblichen Models, während die Models des mittleren und großen Ohres männlich sind.
    
    </li>
    <li><span class="numbers">4</span><span class="strongMagenta">Ergebnis ansehen:</span><br> Hier sehen Sie Ihre Konfiguration sowie ein Modellbeispiel der gewählten Bauform. Darüber hinaus sehen Sie in dem linken Bild wie ein IdO-System, das Ihrer Konfiguration entspricht bzw. dieser am nächsten kommt, im Ohr aussieht. Mit dem Schieberegler unter dem Foto können Sie zwischen drei Perspektiven wechseln.
    <br><br>
    <strong>Tipp:</strong> Sie möchten Ihrem Kunden ein Ex-Hörer Mini Hörsystem zum Vergleich zeigen? Dann klicken Sie auf den Magenta Balken, in dem der Modellname steht, am rechten Rand und das Ohr wird mit einem Ex-Hörer Mini angezeigt. Erneutes Klicken wechselt wieder zu den IdO-Systemen. 
    
    </li>
    <img src="assets/img/klickbereichFunktionen.jpg" class="popupImageFW">

    </p>
 

    `
  },
  anpassbereich: {
    heading: 'Anpass-Bereich',
    content: `
    <p>Der Anpass-Bereich gibt die Hörminderung in dB HL an, die mit dem Hörsystem versorgt werden kann. Der Hörer des IdOs bestimmt den Anpass-Bereich. Die Hörer sind je nach Verstärkungsleistung unterschiedlich groß und haben daher Einfluss auf die Größe des IdO-Systems.</p>
    <img class="popupImageFW" src="assets/img/anpassbereiche.png" alt="Anpassbereiche">`
  },
  nfmi: {
    heading: 'NFMI',
    content: `
    <p>NFMI ist die Abkürzung für Near Field Magnetic Induction. Diese Technologie ermöglicht den wireless Datenaustausch zwischen linkem und rechtem IdO. Sie ist z.B. die Basis für Features wie binaurale Koordination oder Spatial Sound LX. NFMI ermöglicht für alle Velox-basierten Hörsysteme <strong>keine</strong> kabellose Anbindung an externe Geräte oder kabellose Programmierung. Diese Funktionen benötigen 2,4 GHz-Technologie. <br><br>
    Bei den IICs steht die geringe Größe an erster Stelle, deshalb gibt es NFMI nicht für IICs: die Vorteile der NFMI-Technologie sind durch den tiefen Sitz im Gehörgang und ohne Taster ohnehin limitiert.<br><br>
    NFMI ist bei IdOs ab dem CIC optional möglich und kann dort die Größe beeinflussen. Bei 312er & 13er Batterie hat NFMI keinen Einfluss auf die Größe des IdOs und erhöht nur geringfügig die Stromaufnahme. Deshalb ist diese Technik ab ITC/HS ein Standardfeature.  <br><br>
    Die Funktion Tinnitus SoundSupport benötigt sowohl NFMI als auch einen Taster an einem Hörsystem.

    </p>`
  },
  bedien: {
    heading: 'Bedienelemente',
    content: `
    <p>Für die Lautstärkeregelung, den Programmwechsel und das Stummschalten am Hörsystem stehen je nach Versorgung und Kundenwünschen verschiedene Kombinationen der Bedienelemente zur Verfügung. In der Tabelle finden Sie die jeweiligen Mindestkonfigurationen für die gewünschten Bedienmöglichkeiten. Wenn Sie im Modus „Funktionen“ des IdO-Konfigurators bei einer beidohrigen Versorgung Bedienelemente konfigurieren, wird immer angenommen, dass NFMI bei der Bestellung ausgewählt wird (beim CIC) bzw. standardmäßig bei der Bauform verfügbar ist (ab ITC/HS).
    Bitte beachten Sie, dass Sie beim CIC die Funktion NFMI wählen / bestellen müssen, wenn der Kunde mit einem Taster beide Hörsysteme bedienen können soll.
    </p>
    <img class="popupImageFW" src="assets/img/bedienelemente.png" alt="Bedienelemente">
    <p>Die Bedienelemente haben ab ITC/HS kaum einen Einfluss auf die Gerätegröße. Grundsätzlich können Taster und VC-Rädchen an einem Hörsystem angebracht werden. In Einzelfällen kann dieses nicht möglich sein. <br><br>
    Die Funktion Tinnitus SoundSupport benötigt sowohl NFMI als auch einen Taster an einem Hörsystem.
</p>
 `
  },
  mic: {
    heading: '2 Mikrofone',
    content: `
    <p>IIC und CIC arbeiten mit einem Mikrofon. Diese beiden Bauformen bieten kleinstmögliche Hörsysteme und nutzen aufgrund des tiefen Sitzes im Gehörgang den natürlichen Pinna-Effekt. Ab ITC/HS gehören 2 Mikrofone zur Standardausstattung. <br><br>
    Zwei Mikrofone sind Voraussetzung für die Windgeräuschreduktion. 
Und während beim IIC und CIC der Balance-Schritt des OpenSound Navigators aufgrund des natürlichen Pinna-Effektes realisiert wird, geschieht dieses ab ITC/HS über die Zwei-Mikrofontechnologie des Balance-Moduls.
</p>
    `
  },
  ghz: {
    heading: '2,4 GHz Bluetooth® Low Energy',
    content: `
    <p>Ab der Bauform Kanal (ITC/HS) können Kunden optional Konnektivität mit 2,4 GHz Bluetooth® Low Energy nutzen. Sie genießen dadurch viele Vorteile: 
    </p>
    <ul>
      <li>Stereo-Streaming in beide Ohren</li>
      <li>Schnelles und klares Streaming – ohne störende Nebengeräusche</li>
      <li>Großer Audiostreaming Frequenzbereich (7,5 kHz)</li>
      <li>Nutzung der Oticon ON App, z.B. als Fernbedienung</li>
      <li>Kabellose Verbindung zu TV Adapter 3.0, ConnectClip oder Remote Control 3.0</li>
      <li>Direkte kabellose Verbindung zum iPhone®</li>
      <li>Kabellose Übertragung zu allen modernen Smartphones via ConnectClip</li>
      <li>Kabellose Verbindung zum Festnetztelefon (z.B. ConnectClip und Phone Adapter 2 FW 2.0) oder PC (z.B. ConnectClip und Sennheiser BTD 800)</li>
    </ul>

    <img style="max-width: 300px; margin: 30px auto; display: block" src="assets/img/image_ghz.png" alt="2,4 GHz Bluetooth Low Energy">
    <p>Die Größe und insbesondere die Anordnung der 2,4 GHz-Spule kann einen sichtbaren Einfluss auf die IdO-Größe haben. Deshalb ist es wichtig, die Wünsche und Erwartungen des Kunden in Bezug auf Kosmetik und Konnektivität zu kennen. Bei den ITC/HS-Geräten könnte die Kombination von 2,4 GHz Technologie und T-Spule auf engem Raum zu störenden Interferenzen fügen. Deshalb wird diese Kombination für die 312er ITC/HS-Geräte nicht angeboten. Sie steht aber für das Concha-System, das mit 13er Batterie arbeitet, zur Verfügung. <br><br>
    <strong>Hinweis:</strong> Für alle Velox-basierten Hörsysteme reicht NFMI nicht für die kabellose Anbindung an externe Geräte oder die kabellose Programmierung. Diese Funktionen benötigen 2,4 GHz-Technologie.
    </p>
    `
  },
  spule: {
    heading: 'T-Spule',
    content: `
    <p>Die T-Spule ermöglicht die Nutzung von Induktionsschleifen oder von dem FM Funkempfänger Amigo Arc. Eine T-Spule steht optional beim ITC/HS zur Verfügung, solange dieses keine 2,4 GHz-Technologie hat, oder beim Concha-Gerät ITE FS - hier auch in Kombination mit 2,4 GHz-Technologie.</p>`
  },
  versorgung: {
    heading: 'Hier steht der Text zur Versorgung',
    content: `
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo illum, nisi accusamus architecto quia eaque laborum sapiente, harum accusantium voluptas eveniet ipsa qui doloribus cumque! Nemo recusandae ex voluptatibus. Earum.</p>
    <a href="">Platzhalter</a>`
  },
  batteriegroesse: {
    heading: 'Hier steht der Text zur Batteriegröße',
    content: `
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo illum, nisi accusamus architecto quia eaque laborum sapiente, harum accusantium voluptas eveniet ipsa qui doloribus cumque! Nemo recusandae ex voluptatibus. Earum.</p>
    <a href="">Platzhalter</a>`
  },
  spatial: {
    heading: 'Spatial Sound LX und 3D Lärm-Management LX',
    content: `
    <p>Die Funktionen Spatial Sound LX, 3D Lärm-Management und Binaurale Koordination benötigen den kabellosen Datenaustausch zwischen dem linken und rechten Hörsystem. Dieser wird über Near Field Magnetic Induction (NFMI) realisiert. Bei IICs steht die geringe Größe an erster Stelle, deshalb ist NFMI in IICs nicht integriert. Beim CIC ist NFMI optional wählbar. Bei Kanal- und Concha-Geräten (ITC- und ITE FS-Geräten), also den IdOs mit 312er & 13er Batterie, ist NFMI ein Standardfeature. <br><br>
    <strong>Spatial Sound LX: </strong>Die Eingangssignale werden über die insgesamt vier Mikrofone und damit die interauralen Pegeldifferenzen kontinuierlich gemessen. Nach der Signalver-arbeitung werden diese individuellen Pegeldifferenzen an die Trommelfelle des Nutzers übertragen. Somit werden die natürlichen Lautstärkeunterschiede zwischen den Ohren erhalten, die uns die Schalllokalisation ermöglichen. <br>
    Kundennutzen:
    <ul class="listeHinweis">
      <li>Natürlicher Raumklang, stereophones Klangbild, 3D Hören</li>
      <li>Verbessertes selektives Hören </li>
      <li>Leichteres Sprachverstehen</li>
      <li>Leichteres Lokalisieren, bessere Orientierung</li>

    </ul>

    <p>

    Das <strong>3D Lärm-Management LX</strong> arbeitet wie das natürliche Gehör und ergänzt Spatial Sound LX. Durch permanente Signalanalyse und Messung des SNRs erkennen die Hörsysteme asymmetrische Hörsituationen. Um in solchen Situationen das Verstehen auf der besseren Seite zu maximieren und Störgeräusche auf der schlechten Seite weitestgehend zu reduzieren, leiten Hörsysteme mit 3D Lärm-Management eine Reihe von Maßnahmen ein: <br>
    <ol id="regularList">
      <li>Zusätzliche Anhebung der Verstärkung für Sprache um bis zu 2 dB auf dem Ohr mit dem besseren SNR. </li>
      <li>Zusätzliche Lärm-Reduktion um bis zu 6 dB auf dem Ohr mit dem schlechteren SNR.</li>
      <li>Die beiden Hörsysteme werden ständig binaural messtechnisch abgeglichen. </li>
    </ol>
    <p>
    Kundennutzen:
    <ul class="listeHinweis">
      <li>Einfacheres Verstehen in lauter Umgebung</li>
      <li>Angenehmerer Klang in lauter Umgebung</li>
      <li>Es ist einfacher, einem Sprecher zu folgen</li>
      <li>Weniger Hörermüdung</li>
    </ul>
    
    </p>`
  },
  osn: {
    heading: 'OpenSound Navigator inkl. Balance-Modul und Windgeräusch-Management LX (2 Mikrofone)',
    content: `
    <p>Voraussetzung für den Balance-Schritt des OpenSound Navigators sowie die Windgeräuschreduktion sind zwei Mikrofone. Alle Kanal- und Concha-Geräte (ITC/HS- und ITE FS-Geräte) arbeiten mit zwei Mikrofonen. IIC und CIC bieten kleinstmögliche Hörsysteme und haben deshalb jeweils ein Mikrofon. Beim IIC und CIC wird der Balance-Schritt des OpenSound Navigators aufgrund des natürlichen Pinna-Effektes realisiert, da diese Systeme tief im Gehörgang sitzen. Windgeräuschreduktion steht beim IIC und CIC nicht zur Verfügung. Allerdings sind diese Bauformen bereits aufgrund des Sitzes im Gehörgang besser gegen Windturbulenzen geschützt.<br><br>Der <strong>OpenSound Navigator</strong> ersetzt herkömmliche Direktionalitäts- und Lärmunterdrückungssysteme, reduziert Lärm und bewahrt gleichzeitig Sprachinformationen aus allen Richtungen. Diese Technologie erleichtert das Verfolgen verschiedener Sprecher und damit den Wechsel zwischen ihnen. Der OpenSound Navigator (OSN) arbeitet in drei Schritten: Analyse der akustischen Umgebung, Gewichtung von Sprache und anderen Klängen - und dadurch eine bessere Trennung von Sprache und anderen Klängen - über das Balance-Modul und Absenkung von diffusem Lärm über die Lärmreduktion. <br><br>
    Das <strong>Windgeräusch-Management LX</strong> arbeitet parallel zum OpenSound Navigator. Es senkt Windgeräusche bis zu 30 dB ab sogar zwischen einzelnen Worten ab. <br>
    Kundennutzen:
    <ul  class="listeHinweis" style="margin-top: 0">
      <li>Mehr Hörkomfort bei Windgeräuschen</li>
      <li>Besserer Signal-Rausch-Abstand, wenn Wind und Sprache gleichzeitig vorhanden sind</li>
      <li>Absenkung der Windgeräusche auf einen angenehmen, konstanten Windteppich </li>
    </ul>

    </p>`
  },
  tinnitus: {
    heading: 'Tinnitus SoundSupport',
    content: `
    <p>Die Funktion Tinnitus SoundSupport benötigt sowohl NFMI als auch einen Taster an einem Hörsystem. NFMI ist die Abkürzung für Near Field Magnetic Induction. Diese Technologie ermöglicht den kabellosen Datenaustausch zwischen linkem und rechtem IdO.<br><br>Beim Klang-Generator <strong>Tinnitus SoundSupport™</strong> stehen vier verschiedene breitbandige Klänge zur Verfügung sowie drei natürliche Klänge, die wie Meeresrauschen klingen. Die Meeresklänge können im Pegel individualisiert werden. Die breitbandigen Klänge können im Pegel, in der Modulation und im Frequenzgang an die Wünsche des Kunden angepasst werden. <br>
    Kundennutzen: <br>
    <ul class="listeHinweis" style="margin-top: 0">
      <li>Ablenkung von den Ohrgeräuschen</li>
      <li>Entspannung durch die wohltuenden Klänge</li>
      <li>Möglichkeit, die Tinnitus-Wahrnehmung zu beeinflussen</li>
    </ul>
    </p>`
  },
  taster2: {
    heading: 'Hier steht der Text zu Tastern an beiden Hörsystemen',
    content: `
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo illum, nisi accusamus architecto quia eaque laborum sapiente, harum accusantium voluptas eveniet ipsa qui doloribus cumque! Nemo recusandae ex voluptatibus. Earum.</p>
    <a href="">Platzhalter</a>`
  },
  tasterVC: {
    heading: 'Hier steht der Text zu Taster an einem Hörsystem und VC am anderen',
    content: `
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo illum, nisi accusamus architecto quia eaque laborum sapiente, harum accusantium voluptas eveniet ipsa qui doloribus cumque! Nemo recusandae ex voluptatibus. Earum.</p>
    <a href="">Platzhalter</a>`
  },
  taster: {
    heading: 'Hier steht der Text zu Taster an einem Hörsystem',
    content: `
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo illum, nisi accusamus architecto quia eaque laborum sapiente, harum accusantium voluptas eveniet ipsa qui doloribus cumque! Nemo recusandae ex voluptatibus. Earum.</p>
    <a href="">Platzhalter</a>`
  },
  vc: {
    heading: 'Hier steht der Text zu VC-Rad an einem Hörsystem',
    content: `
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo illum, nisi accusamus architecto quia eaque laborum sapiente, harum accusantium voluptas eveniet ipsa qui doloribus cumque! Nemo recusandae ex voluptatibus. Earum.</p>
    <a href="">Platzhalter</a>`
  },
  autophone: {
    heading: 'AutoPhone',
    content: `
    <p>AutoPhone sorgt dafür, dass automatisch ein Telefonprogramm aktiviert wird, wenn man den Telefonhörer an das Hörsystem hält. Nicht alle Telefone können AutoPhone aktivieren. Eventuell muss ein spezieller Magnet an die Hörermuschel des Telefons geklebt werden. Der Magnet ist bei Oticon erhältlich.</p>`
  },
  batterie: {
    heading: 'Hier steht der Text zur Batterie',
    content: `
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo illum, nisi accusamus architecto quia eaque laborum sapiente, harum accusantium voluptas eveniet ipsa qui doloribus cumque! Nemo recusandae ex voluptatibus. Earum.</p>
    <a href="">Platzhalter</a>`
  },
  infoGewinn:{
    heading: 'Gewinnspiel EUHA 2018',
    content: `
    <p><strong>Teilnahmebedingungen: </strong><br>
    Das Gewinnspiel läuft vom 17.10. – 19.10.2018. Teilnahme erfolgt via den drei iPad-Terminals. Teilnehmen können alle, ausgenommen Mitarbeiter der Herstellerfirmen und Oticon-Mitarbeiter. Eine Barauszahlung der Gewinne ist nicht möglich. Die Gewinner werden per E-Mail benachrichtigt. Der Rechtsweg ist ausgeschlossen. <br><br>
    
    <strong>Die Preise:<br><br>
    1. Preis:</strong><br>
Amazon-Gutschein im Wert von 150 Euro<br>
<img src="assets/img/preis1.png" alt="" width="112" style="top: 20px"><br><br>
<strong>2. und 3. Preis</strong><br>
Echo-Dot von Amazon<br>
<img src="assets/img/preis2.png" alt="" width="132" style="margin-top: 10px"><br><br>
<strong>4. bis 6. Preis</strong><br>
„Warum wir tun, was wir tun“ von Jens Förster<br>
<img src="assets/img/preis3.png" alt="" width="150" style="margin-top: 10px">

    </p>
    `
  }

};