const breadcrumb = $('#breadcrumb');
const step = $('.step');
let breadcrumbLink = $('#breadcrumb > div');

breadcrumbLink.on('click', function () {
  let targetSelector = this.dataset.step;
  showResult();
  showResultFunktionen();




  if ($(this).next().hasClass('active') || $(this).next().hasClass('activated') || $(this).hasClass('activated')) {
    breadcrumbLink.removeClass('active');
    $(this).addClass('active');
    step.removeClass('active');
    $('#' + targetSelector).addClass('active').addClass('activated');
  }
  else {

  }
})

$('#bc1').on('click', function () {
  reset();
})

$(function () {
  for (var i = 0; i < (breadcrumbLink.length - 1); i++) {
    breadcrumbLink[i].addEventListener('click', clearResult())
  }
})

// Refresh Result


function reset() {

  result = {
    'form': '',
    'image': '',
    'anpassbereich': '',
    'nfmi': false,
    'bedienelemente': false,
    'mikrofone': false,
    'ghz': false,
    'tSpule': false,
    'baseConfig': [],
    'earSize': '',
    'sprite': ''
  };
  breadcrumbLink.removeClass('active').removeClass('activated');
  $('#bc1').addClass('active').addClass('activated')
}