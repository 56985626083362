// Popup Alert

alertMsg.on('click', function () {


  $('#popupAlert').addClass('active');


  if ($(this).closest('.configSection').hasClass('kanal')) {

    let content = `
        <a class="close" onclick="closePopup()"></a>
        <h2>${popupAlert.heading}</h2>
        <h3>${popupAlert.subtitle}</h3>
        <div class="soft-row">
          <div onclick="link${popupAlert.alert1[0]}();" class="alertDevice">
            <img class="${popupAlert.alert1[0]}" src="assets/img/${popupAlert.alert1[2]}" alt="${popupAlert.alert1[1]}">
            <p>${popupAlert.alert1[1]}</p>
          </div>
        </div>`;
    $('#popupAlert .popupContent').html(content);

  }
  else {
    let alertContent = this.dataset.popup;
    var alertLinks = '';
    for (var i = 0; i < popupAlert[alertContent].length; i++) {
      alertLinks += '<div onclick="link' + popupAlert[alertContent][i][0] + '();" class="alertDevice"><img class="' + popupAlert[alertContent][i][0] + '" src="assets/img/' + popupAlert[alertContent][i][2] + '" alt="' + popupAlert[alertContent][i][1] + '"><p>' + popupAlert[alertContent][i][1] + '</p></div>'
    }

    let content = '<a class="close" onclick="closePopup()"></a><h2>' + popupAlert.heading + '<h3>' + popupAlert.subtitle + '</h3><div class="soft-row">' + alertLinks + '</div>';
    $('#popupAlert .popupContent').html(content);
  }

});



// Popup Info

info.on('click', function () {
  let infoContent = this.dataset.popup;

  $('#popupInfo').addClass('active');

  let contentInfo = `
      <a class="close" onclick="closePopup()"></a>
      <div class="hinweisContent">
      <h2>${popupHinweis[infoContent].heading}</h2>
      ${popupHinweis[infoContent].content}</div>`;
  $('#popupInfo .popupContent').html(contentInfo);
})

$('#popupInfo').on('click', function () {
  $('.popup').removeClass('active');
})

function printPage(){
  // $('#printPage').show();
  // $('#printPage2').show();
  window.print();
}



$('.weiterBtn1').on('click', function(){
  if($(this).hasClass('active')){}
  else {
    $('#popupAuswahl').show()
  }
})

$('#popupAuswahl').on('click', function(){
  $('#popupAuswahl').hide()

})

$('#bc2').on('click', function(){
  if($(this).hasClass('activated')){}
  else {
    $('#popupAuswahl').show()

  }
})


$('body').on('click', function(){
  $('#printPage').hide();

})