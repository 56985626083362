function idleLogout() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well      
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well 
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeypress = resetTimer;   
    window.addEventListener('scroll', resetTimer, true); // improved; see comments

    function yourFunction() {
        
        // your function for too long inactivity goes here
        if(window.location.href !== 'index.html'){
            window.location.href = 'index.html';
        }
    }

    function resetTimer() {
        clearTimeout(t);
        t = setTimeout(yourFunction, 20000);  // time is in milliseconds
    }
}
idleLogout();