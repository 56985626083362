const inputsFunktionen = $('.modusFunktionen input[type="radio"]');
const inputsFunktionenCheckbox = $('.modusFunktionen input[type="checkbox"]');
const configList = $('#configList');


const iicThumb = $('.IIC_thumb'),
  cicThumb = $('.CIC_thumb'),
  kanalThumb = $('.Kanal_thumb'),
  conchaThumb = $('.Concha_thumb'),
  iic = $('#chooseIIC'),
  cic = $('#chooseCIC'),
  itcKanal = $('#chooseITCkanal'),
  itcConcha = $('#chooseITCconcha'),
  iicPopup = $('.chooseIICPopup'),
  cicPopup = $('.chooseCICPopup'),
  itcKanalPopup = $('.chooseITCkanalPopup'),
  itcConchaPopup = $('.chooseITCconchaPopup'),
  next1 = $('#nextFunktion');



const monaural = $('#cbVersorgung01');
const binaural = $('#cbVersorgung02');
const anpassbereich = $('input[name="anpass"]');
const checkboxBatterie = $('.chb2');


//===================================================
//Versorgung 
//===================================================

monaural.on('change', function () {

  resultFunktionen.versorgung = this.value;

  if (resultFunktionen.anpassbereich !== '') {
    next1.addClass('active');
    $('#bc2').addClass('activated')
  } else {
    next1.removeClass('active');
  }

  $('.monaural').hide();
  deviceVisibility();

  showResultFunktionen()
  output();



});


binaural.on('change', function () {

  $('.monaural').show();

  resultFunktionen.versorgung = this.value;

  if (resultFunktionen.anpassbereich !== '') {
    next1.addClass('active');
    $('#bc2').addClass('activated')
  } else {
    next1.removeClass('active');
  }
  deviceVisibility();

  showResultFunktionen()
  output();


});



//==================================================
//Anpassbereich 
//==================================================

anpassbereich.on('change', function () {

  resultFunktionen.anpassbereich = this.value;
  if (resultFunktionen.versorgung !== '') {
    next1.addClass('active');
    $('#bc2').addClass('activated')
  }


  if (resultFunktionen.anpassbereich === '75') {
    $('#batt13').hide();

  }

  if (resultFunktionen.anpassbereich === '90') {
    $('#batt13').hide();
    $('#batt10').hide();

    $('#batterie02').prop('checked', true)
    resultFunktionen.batterie = '312';

    $('#audiologie02').prop('checked', true)
    resultFunktionen.osn = true;

    if (resultFunktionen.versorgung === 'Beidohrig') {
      $('#audiologie01').prop('checked', true)
      resultFunktionen.spatialSound = true;
    }


  }


  if (resultFunktionen.anpassbereich === '100') {
    $('#batt10').hide();
  }
  deviceVisibility();

  showResultFunktionen()
  output();


});

//===================================================
//Weiterbutton Show Output 
//===================================================
next1.on('click', output);


//===================================================
//Batterie 
//===================================================

checkboxBatterie.on('change', function () {

  var checked = $(this).is(':checked');
  checkboxBatterie.prop('checked', false);
  if (checked) {
    $(this).prop('checked', true);
    resultFunktionen.batterie = this.value;
  } else {
    resultFunktionen.batterie = '';
  }

  if (resultFunktionen.batterie === '10') {

    //Autophone weg
    $('#optionen02').prop('checked', false);
    resultFunktionen.autophone = false;

    //T-Spule weg
    $('#optionen01').prop('checked', false);
    resultFunktionen.spule = false;

    //2,4 GHz weg
    $('#konnektivitaet').prop('checked', false);
    resultFunktionen.ghz = false;

    //OSN weg
    $('#audiologie02').prop('checked', false);
    resultFunktionen.osn = false;

    //Bedienung
    if (
      resultFunktionen.bedienung === 'Lautstärke: VC-Rad an einem Hörsystem' ||
      resultFunktionen.bedienung === 'Lautstärke: VC-Rad' ||
      resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster an einem Hörsystem und VC-Rad am anderen' ||
      resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad an einem Hörsystem' ||
      resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad'
    ) {
      resultFunktionen.bedienung = '';
      resultFunktionen.bedienelemente = false;
    }

    $('#bedienung02').prop('checked', false);
    $('#bedienung05').prop('checked', false);
    $('#bedienung04').prop('checked', false);

  }

  if (resultFunktionen.batterie === '312') {
    $('#audiologie02').prop('checked', true)
    resultFunktionen.osn = true;
    if (resultFunktionen.versorgung === 'Beidohrig') {
      $('#audiologie01').prop('checked', true)
      resultFunktionen.spatialSound = true;

    }

    if (resultFunktionen.ghz && resultFunktionen.spule) {
      $('#optionen01').prop('checked', false);
      resultFunktionen.spule = false;
    }


  }

  if (resultFunktionen.batterie == '13') {
    $('#audiologie02').prop('checked', true)
    resultFunktionen.osn = true;
    if (resultFunktionen.versorgung === 'Beidohrig') {

      $('#audiologie01').prop('checked', true)
      resultFunktionen.spatialSound = true;
    }
  }
  deviceVisibility();
  showResultFunktionen()
  output();

});

const bedienelemente = $('.chb');

//===================================================
//Bedienelemente
//===================================================

bedienelemente.on('change', function () {


  var checked = $(this).is(':checked');
  bedienelemente.prop('checked', false);
  if (checked) {
    $(this).prop('checked', true);
    let storedValue = this.value

    if (resultFunktionen.versorgung === 'Monaural') {
      if (storedValue === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad an einem Hörsystem') {
        storedValue = 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad'
      }
      else if (storedValue === 'Nur Programme / Stummschalten: Taster an einem Hörsystem') {
        storedValue = 'Nur Programme / Stummschalten: Taster'
      }
      else if (storedValue === 'Lautstärke: VC-Rad an einem Hörsystem') {
        storedValue = 'Lautstärke: VC-Rad'

      } else {
        storedValue = this.value;
      }
    } 
    resultFunktionen.bedienung = storedValue;
    resultFunktionen.bedienelemente = true;

    console.log(storedValue)


    if (resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad' || resultFunktionen.bedienung === 'Nur Programme / Stummschalten: Taster') {
      if(resultFunktionen.spule || resultFunktionen.autophone || resultFunktionen.ghz || resultFunktionen.batterie === '312' || resultFunktionen.batterie === '13' || resultFunktionen.osn){
        $('#audiologie03')[0].checked = true;
        resultFunktionen.tinnitus = true;
      }
    }
    if (resultFunktionen.bedienung === 'Lautstärke: VC-Rad') {
      $('#audiologie03')[0].checked = false;
      resultFunktionen.tinnitus = false;
    }

  } else {
    resultFunktionen.bedienung = '';
    resultFunktionen.bedienelemente = false;
  }

  //Monaural

  if (resultFunktionen.versorgung === 'Monaural') {

    if (
      resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad an einem Hörsystem' ||
      resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad' ||
      resultFunktionen.bedienung === 'Lautstärke: VC-Rad' ||
      resultFunktionen.bedienung === 'Lautstärke: VC-Rad an einem Hörsystem') {
      if (resultFunktionen.batterie === '10') {
        $('#batterie01')[0].checked = false;
        resultFunktionen.batterie = '';
      }
    }


    

    if (!resultFunktionen.bedienelemente) {
      $('#audiologie03')[0].checked = false;
      resultFunktionen.tinnitus = false;
      resultFunktionen.bedienelemente = false;
    }
  }

  //Binaural

  if (resultFunktionen.versorgung === "Beidohrig") {
    if (resultFunktionen.bedienelemente && resultFunktionen.bedienung !== 'Nur Programme / Stummschalten: Taster an einem Hörsystem') {
      $('#audiologie01').prop('checked', true);
      resultFunktionen.spatialSound = true;
      // $('#audiologie02').prop('checked', true);
      // resultFunktionen.osn = true;
    }

    if (resultFunktionen.bedienung == 'Nur Programme / Stummschalten: Taster an einem Hörsystem') {
      $('#audiologie01').prop('checked', true);
      resultFunktionen.spatialSound = true;
    }

    if (resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster an einem Hörsystem und VC-Rad am anderen' ||
      resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad an einem Hörsystem' ||
      resultFunktionen.bedienung === 'Lautstärke: VC-Rad an einem Hörsystem') {
      if (resultFunktionen.batterie === '10') {
        $('#batterie01')[0].checked = false;
        resultFunktionen.batterie = '';
      }
    }

    if (resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster an beiden Hörsystemen') {
      $('#audiologie03').prop('checked', true);
      resultFunktionen.tinnitus = true;
      $('#audiologie01').prop('checked', true);
      resultFunktionen.spatialSound = true;

    }

    if (resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster an beiden Hörsystemen' && resultFunktionen.spatialSound) {
      $('#audiologie03').prop('checked', true);
      resultFunktionen.tinnitus = true;
    } else {
      $('#audiologie03').prop('checked', false);
      resultFunktionen.tinnitus = false;
    }

  }


  deviceVisibility();
  showResultFunktionen()
  output();
});

//===================================================
//OpenSound Navigator
//===================================================

$('#audiologie02').on('change', function () {

  resultFunktionen.osn = this.checked;

  if (resultFunktionen.osn) {
    if (resultFunktionen.batterie === '10') {
      $('#batterie01')[0].checked = false;
      resultFunktionen.batterie = '';
    }
  }
  if (resultFunktionen.versorgung === 'Beidohrig' && resultFunktionen.osn) {
    $('#audiologie01').prop('checked', true);
    resultFunktionen.spatialSound = true;

  }
  deviceVisibility();
  showResultFunktionen()
  output();

})

//===================================================
//Spatial Sound
//===================================================

$('#audiologie01').on('change', function () {

  resultFunktionen.spatialSound = this.checked;

  if (resultFunktionen.spatialSound) {

    if (resultFunktionen.versorgung === 'Monaural') {
      if (resultFunktionen.bedienung === 'Nur Programme / Stummschalten: Taster an einem Hörsystem' || resultFunktionen.bedienung === 'Nur Programme / Stummschalten: Taster') {
        $('#audiologie03')[0].checked = true;
        resultFunktionen.tinnitus = true;
      }
    } else {
      if (resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster an beiden Hörsystemen') {
        $('#audiologie03')[0].checked = true;
        resultFunktionen.tinnitus = true;
      }

    }
  }

  if (!resultFunktionen.spatialSound && resultFunktionen.tinnitus && resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster an beiden Hörsystemen') {
    $('#audiologie03').prop('checked', false)
    resultFunktionen.tinnitus = false;
    $('#bedienung01').prop('checked', false);
    resultFunktionen.bedienelemente = false;
    resultFunktionen.bedienung = '';
  }

  if (!resultFunktionen.spatialSound && resultFunktionen.bedienung === 'Nur Programme / Stummschalten: Taster an einem Hörsystem' || !resultFunktionen.spatialSound && resultFunktionen.bedienung === 'Nur Programme / Stummschalten: Taster') {
    $('#bedienung03')[0].checked = false;
    resultFunktionen.bedienung = '';
    resultFunktionen.bedienelemente = false
  }
  deviceVisibility();
  showResultFunktionen()
  output();

})

//===================================================
//Tinnitus
//===================================================

$('#audiologie03').on('change', function () {

  resultFunktionen.tinnitus = this.checked;

  if (resultFunktionen.tinnitus) {

    if (resultFunktionen.versorgung === 'Monaural') {

      $('#bedienung04')[0].checked = false;

      if (resultFunktionen.bedienung !== 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad') {
        $('#bedienung03')[0].checked = true;
        resultFunktionen.bedienung = 'Nur Programme / Stummschalten: Taster';
      } else {

      }

    } else {
      $('#bedienung05')[0].checked = false;
      $('#bedienung04')[0].checked = false;
      $('#bedienung03')[0].checked = false;
      $('#bedienung02')[0].checked = false;
      $('#audiologie01')[0].checked = true;
      resultFunktionen.spatialSound = true;
      $('#bedienung01')[0].checked = true;
      resultFunktionen.bedienung = 'Programme, Stummschalten & Lautstärke: Taster an beiden Hörsystemen';

    }
  }

  deviceVisibility();
  showResultFunktionen()
  output();

})

//===================================================
//Autophone
//===================================================

$('#optionen02').on('change', function () {

  resultFunktionen.autophone = this.checked;

  $('#audiologie02').prop('checked', true)
  resultFunktionen.osn = true;
  if (resultFunktionen.versorgung === 'Beidohrig') {

    $('#audiologie01').prop('checked', true)
    resultFunktionen.spatialSound = true;
  }

  if (resultFunktionen.autophone) {
    if (resultFunktionen.batterie === '10') {
      $('#batterie01')[0].checked = false;
      resultFunktionen.batterie = '';
    }

  }
  deviceVisibility();
  showResultFunktionen()
  output();

})

//===================================================
//Konnektivität
//===================================================

$('#konnektivitaet').on('change', function () {

  resultFunktionen.ghz = this.checked;

  if(resultFunktionen.ghz){
    $('#audiologie02').prop('checked', true)
    resultFunktionen.osn = true;
  }



  if (resultFunktionen.versorgung === 'Beidohrig') {
    $('#audiologie01').prop('checked', true)
    resultFunktionen.spatialSound = true;

  }

  if (resultFunktionen.ghz) {

    if (resultFunktionen.batterie === '10') {
      $('#batterie01')[0].checked = false;
      resultFunktionen.batterie = '';
    }
    if (resultFunktionen.spule) {
      if (resultFunktionen.batterie === '312') {
        $('#batterie02')[0].checked = false;
        resultFunktionen.batterie = '';
      }
    }
    if (resultFunktionen.anpassbereich === '75' || resultFunktionen.anpassbereich === '90') {
      $('#optionen01')[0].checked = false;
      resultFunktionen.spule = false;
    }


  }
  deviceVisibility();
  showResultFunktionen()
  output();

})
//===================================================
//Spule
//===================================================

$('#optionen01').on('change', function () {

  resultFunktionen.spule = this.checked;

  if(resultFunktionen.spule){
    
      $('#audiologie02').prop('checked', true)
      resultFunktionen.osn = true;
    
      if (resultFunktionen.versorgung === 'Beidohrig') {
        $('#audiologie01').prop('checked', true)
        resultFunktionen.spatialSound = true;
    
      }

  }

  if (resultFunktionen.spule) {
    if (resultFunktionen.batterie === '10') {
      $('#batterie01')[0].checked = false;
      resultFunktionen.batterie = '';
    }
    if (resultFunktionen.ghz) {
      if (resultFunktionen.batterie === '312') {
        $('#batterie02')[0].checked = false;
        resultFunktionen.batterie = '';
      }
    }
    if (resultFunktionen.anpassbereich === '75' || resultFunktionen.anpassbereich === '90') {
      $('#konnektivitaet')[0].checked = false;
      resultFunktionen.ghz = false;
    }

  }
  deviceVisibility();
  showResultFunktionen()
  output();

})



//===================================================
// Konfigurationsliste
//===================================================
function output() {

  let outputList = '';
  if (resultFunktionen.versorgung !== '') {
    outputList += `
    <tr>
      <td class="tableLeft">Versorgung: </td>
      <td class="tableRight">${resultFunktionen.versorgung}</td>
    </tr>`
  }
  if (resultFunktionen.anpassbereich !== '') {
    outputList += `
    <tr>
    <td class="tableLeft">Anpassbereich: </td>
    <td class="tableRight">${resultFunktionen.anpassbereich}</td>
    </tr>`
  }
  if (resultFunktionen.batterie !== '') {
    outputList += `
    <tr>
    <td class="tableLeft">Batterie: </td>
    <td class="tableRight">${resultFunktionen.batterie}</td>
    </tr>`
  }
  if (resultFunktionen.bedienung !== '') {
    outputList += `
    <tr>
    <td class="tableLeft">Bedienung: </td>
    <td class="tableRight">${resultFunktionen.bedienung}</td>
    </tr>`
  }
  if (resultFunktionen.spatialSound) {
    outputList += `
    <tr>
    <td class="tableLeft">Funktionen: </td>
    <td class="tableRight">Spatial Sound LX und 3D Lärm-Management LX (NFMI)
    </td>
    </tr>`
  }
  if (resultFunktionen.osn) {
    outputList += `
    <tr>
    <td class="tableLeft">Funktionen: </td>
    <td class="tableRight">Balance-Modul und Windgeräusch-Management LX (2 Mikrofone)</td>
    </tr>`
  }
  if (resultFunktionen.tinnitus) {
    outputList += `
    <tr>
    <td class="tableLeft">Funktionen: </td>
    <td class="tableRight">Tinnitus SoundSupport™ (NFMI)</td>
    </tr>`
  }
  if (resultFunktionen.ghz) {
    outputList += `
    <tr>
    <td class="tableLeft">Konnektivität: </td>
    <td class="tableRight">2,4 GHz Bluetooth Low Energy</td>
    </tr>`
  }
  if (resultFunktionen.spule) {
    outputList += `
    <tr>
    <td class="tableLeft">Optionen: </td>
    <td class="tableRight">T-Spule</td>
    </tr>`
  }
  if (resultFunktionen.autophone) {
    outputList += `
    <tr>
    <td class="tableLeft">Optionen: </td>
    <td class="tableRight">AutoPhone</td>
    </tr>`
  }
  if (resultFunktionen.bauform !== '') {
    outputList += `
    <tr>
    <td class="tableLeft">Bauform: </td>
    <td class="tableRight">${ resultFunktionen.bauform}</td>
    </tr>`
  }
  //===================================================
  // Liste ausgeben
  //===================================================

  configList.html(outputList);
  showResultFunktionen();
}


function resetPreviewList() {
  configList.html('');
  showResultFunktionen();
}

//===================================================
// Weiter Button Funktion 
//===================================================

$('#bc2').on('click', function () {
  if ($(this).hasClass('activated')) {
    showResultFunktionen();
  }

})
$('#bc1').on('click', function () {

  const currentUrl = window.location.href;

  window.location.href = currentUrl

})

$('#nextFunktion').on('click', function () {
  if ($(this).hasClass('active')) {
    breadcrumbLink.removeClass('active');

    $('#bc2').addClass('active').addClass('activated');
    step.removeClass('active');
    $('#config02').addClass('active');
    showResultFunktionen();

  }
})


//===================================================
// Bauformen
//===================================================

function chooseIIC() {
  resultFunktionen.bauform = 'IIC'
  $('.choose').removeClass('active');
  $('#chooseIIC').addClass('active')
  $('.nxtBtn').addClass('active')
  $('#bc3').addClass('activated')

  if (resultFunktionen.earSize !== '') {
    $('#bc3').addClass('activated')
    $('#bc4').addClass('activated')
    $('#nextFunktion.nxtBtn').addClass('active')
  }
  $('#popupBauform').hide();
  output();
  showResultFunktionen();

}

function chooseCIC() {
  resultFunktionen.bauform = 'CIC'
  $('.choose').removeClass('active');
  $('#chooseCIC').addClass('active');
  $('.nxtBtn').addClass('active')
  $('#bc3').addClass('activated')

  if (resultFunktionen.earSize !== '') {
    $('#bc3').addClass('activated')
    $('#bc4').addClass('activated')
    $('#nextFunktion.nxtBtn').addClass('active')
  }
  $('#popupBauform').hide();
  output();
  showResultFunktionen();

}

function chooseKanal() {
  resultFunktionen.bauform = 'Kanal / Halb-Concha'
  $('.choose').removeClass('active');
  $('#chooseITCkanal').addClass('active')
  $('.nxtBtn').addClass('active')
  $('#bc3').addClass('activated')

  if (resultFunktionen.earSize !== '') {
    $('#bc3').addClass('activated')
    $('#bc4').addClass('activated')
    $('#nextFunktion.nxtBtn').addClass('active')
  }
  $('#popupBauform').hide();
  output();
  showResultFunktionen();

}

function chooseConcha() {
  resultFunktionen.bauform = 'Concha'
  $('.choose').removeClass('active');
  $('#chooseITCconcha').addClass('active')
  $('.nxtBtn').addClass('active')
  $('#bc3').addClass('activated')

  if (resultFunktionen.earSize !== '') {
    $('#bc3').addClass('activated')
    $('#bc4').addClass('activated')
    $('#nextFunktion.nxtBtn').addClass('active')
  }
  $('#popupBauform').hide();
  output();
  showResultFunktionen();
}

function step3() {
  if ($('.nxtBtn').hasClass('active')) {

    breadcrumbLink.removeClass('active');
    $('#bc3').addClass('active').addClass('activated');
    step.removeClass('active');
    $('#config03').addClass('active');
  } else {
    $('#popupBauform').show();
  }
}
$('.reset').on('click', function () {
  resultFunktionen = {
    versorgung: '',
    anpassbereich: '',
    batterie: '',
    spatialSound: false,
    osn: false,
    tinnitus: false,
    bedienung: '',
    ghz: false,
    spule: false,
    autophone: false,
    earSize: '',
    bauform: ''
  };
  breadcrumbLink.removeClass('active').removeClass('activated');
  $('#bc1').addClass('active').addClass('activated');


})

$('#popupBauform').on('click', function () {
  $('#popupBauform').hide();
})

$('.config03Popup').on('click', function () {
  if ($('#bc2').hasClass('active')) {
    $('#popupBauform').show();

  }

})

//===================================================
// Ohrgröße
//===================================================

$('.ear').on('click', function () {
  var earValue = this.dataset.size;
  var earOutput = this.dataset.value;
  resultFunktionen.earSize = earValue;
  resultFunktionen.earValue = earOutput;
  step.removeClass('active');
  fourthStep.addClass('active');

  breadcrumbLink.removeClass('active');
  $('#bc4').addClass('active').addClass('activated');
  output()
  showResultFunktionen();

})

function deviceVisibility() {

  iic.removeClass('hidden');
  cic.removeClass('hidden');
  itcConcha.removeClass('hidden');
  itcKanal.removeClass('hidden');
  iicPopup.removeClass('hidden');
  cicPopup.removeClass('hidden');
  itcConchaPopup.removeClass('hidden');
  itcKanalPopup.removeClass('hidden');

  //===================================================
  //IIC
  //===================================================

  if (resultFunktionen.anpassbereich === '90' ||
    resultFunktionen.anpassbereich === '100' ||
    resultFunktionen.spatialSound ||
    resultFunktionen.osn ||
    resultFunktionen.tinnitus ||
    resultFunktionen.batterie === '312' ||
    resultFunktionen.batterie === '13' ||
    resultFunktionen.ghz ||
    resultFunktionen.autophone ||
    resultFunktionen.spule ||
    resultFunktionen.bedienelemente
  ) {
    iic.addClass('hidden')
    iicPopup.addClass('hidden')
    if ($('#chooseIIC').hasClass('active')) {
      $('#chooseIIC').removeClass('active');
      resultFunktionen.bauform = '';
      $('.nxtBtn').removeClass('active')
      $('#bc3').removeClass('activated')
    }
  }

  //===================================================
  //CIC
  //===================================================

  if (resultFunktionen.anpassbereich === '90' ||
    resultFunktionen.anpassbereich === '100' ||
    resultFunktionen.osn ||
    resultFunktionen.batterie === '312' ||
    resultFunktionen.batterie === '13' ||
    resultFunktionen.ghz ||
    resultFunktionen.autophone ||
    resultFunktionen.spule ||
    resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster an einem Hörsystem und VC-Rad am anderen' ||
    resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad an einem Hörsystem' ||
    resultFunktionen.bedienung === 'Programme, Stummschalten & Lautstärke: Taster und VC-Rad' ||
    resultFunktionen.bedienung === 'Lautstärke: VC-Rad' ||
    resultFunktionen.bedienung === 'Lautstärke: VC-Rad an einem Hörsystem'
  ) {
    cic.addClass('hidden')
    cicPopup.addClass('hidden')
    if ($('#chooseCIC').hasClass('active')) {
      $('#chooseCIC').removeClass('active');
      resultFunktionen.bauform = '';
      $('.nxtBtn').removeClass('active')
      $('#bc3').removeClass('activated')
    }
  }

  //===================================================
  //Kanal
  //===================================================

  if (
    resultFunktionen.batterie === '10' ||
    resultFunktionen.batterie === '13' ||
    (resultFunktionen.ghz && resultFunktionen.spule)
  ) {
    itcKanal.addClass('hidden')
    itcKanalPopup.addClass('hidden')
    if ($('#chooseITCkanal').hasClass('active')) {
      $('#chooseITCkanal').removeClass('active');
      resultFunktionen.bauform = '';
      $('.nxtBtn').removeClass('active')
      $('#bc3').removeClass('activated')
    }
  }

  //===================================================
  //Concha
  //===================================================

  if (
    resultFunktionen.batterie === '10' ||
    resultFunktionen.batterie === '312' ||
    resultFunktionen.anpassbereich === '75' ||
    resultFunktionen.anpassbereich === '90'
  ) {
    itcConcha.addClass('hidden')
    itcConchaPopup.addClass('hidden')
    if ($('#chooseITCconcha').hasClass('active')) {
      $('#chooseITCconcha').removeClass('active');
      resultFunktionen.bauform = '';
      $('.nxtBtn').removeClass('active')
      $('#bc3').removeClass('activated')
    }
  }

}

//===================================================
// Result Content
//===================================================

function showResultFunktionen() {




  for (var i = 0; i < typesFunktion.length; i++) {
    let listFunktionen = '';

    if (typesFunktion[i].bauform == resultFunktionen.bauform &&
      typesFunktion[i].anpassbereich == resultFunktionen.anpassbereich &&
      typesFunktion[i].bedienelemente == resultFunktionen.bedienelemente &&
      typesFunktion[i].ghz == resultFunktionen.ghz &&
      typesFunktion[i].spule == resultFunktionen.spule
    ) {

      for (var j = 0; j < typesFunktion[i].baseConfig.length; j++) {
        listFunktionen += '<li>' + typesFunktion[i].baseConfig[j] + '</li>';
      }
      listFunktionen += `<li>Anpass-Bereich: ${typesFunktion[i].anpassbereich}</li>`
      listFunktionen += `<li>${resultFunktionen.earValue}</li>`
      listFunktionen += `<li>${resultFunktionen.versorgung}e Versorgung</li>`
      if (resultFunktionen.bedienung != '') {
        listFunktionen += `<li>${resultFunktionen.bedienung}</li>`
      }
      if (resultFunktionen.osn) {
        listFunktionen += `<li>Balance-Modul (OpenSound Navigator) und Windgeräusch-Management LX (2 Mikrofone)</li>`
      }
      if (resultFunktionen.spatialSound) {
        listFunktionen += `<li>Spatial Sound LX und 3D Lärm-Management LX (NFMI)
        </li>`
      }
      if (resultFunktionen.autophone) {
        listFunktionen += `<li>Autophone</li>`
      }
      if (resultFunktionen.tinnitus) {
        listFunktionen += `<li>Tinnitus SoundSupport™ (NFMI)</li>`
      }

 


      if(resultFunktionen.earSize !== ''){var earPath = resultFunktionen.earSize} else{var earPath = 'small'}
      let outputResultFunktionen = `
            <div class="spriteContainer col-1">
              <div class="spriteImage" id="hdoToggle" style="background: url(assets/img/${earPath}/${typesFunktion[i].sprite}) 50% center no-repeat"></div>
              <div class="spriteImage hdoOverlay" style="background: url(assets/img/${earPath}/hdo_sprite.jpg) 50% center no-repeat"></div>
              <div class="spriteSelection">
                <input type="range" value="50" step="50" min="0" max="100" id="range">
              </div>
              
            </div>
            <div class="resultContent col-2">
              <div class="resultHeader">${typesFunktion[i].bauform}<div id="secretButton" onclick="$('.hdoOverlay').toggleClass('active');"></div></div>
              <div class="resultBody">
              <div class="resultList col-1">
                <h3>Konfiguration</h3>
                  <ul>
                    ${listFunktionen}
                  </ul>
                  <div class="sternchen" id="resultHinweis"><strong>Hinweis: </strong>Das Foto links zeigt das Modell, das der gewählten Konfiguration am nächsten kommt.</div>

              </div>
              <div class="resultImage col-1">
                <img src="assets/img/${typesFunktion[i].image}" class="${typesFunktion[i].class}" alt="Oticon Opn">
                <p class="sternchen">Modellbeispiel</p>
              </div>
              </div>
              <div id="print"onclick="printPage()"><img alt="Seite Ausdrucken" src='assets/img/printer.svg'>Drucken</div>
            </div>
          `;
      let printFunktionen = `
      
              <div class="resultHeader">${typesFunktion[i].bauform}</div>
              <div class="resultBody">
              <div class="spriteImage"> <img src="assets/img/${earPath}/${typesFunktion[i].sprite}">></div>

              <div class="resultList col-1">
                <h3>Konfiguration</h3>
                  <ul>
                    ${listFunktionen}
                  </ul>
              </div>
              <div class="resultImage col-1">
                <img src="assets/img/${typesFunktion[i].image}" class="${typesFunktion[i].class}" alt="Oticon Opn">
              </div>
              </div>
          `;
      $('#ergebnis').html(outputResultFunktionen)
      $('#printPage .popupContent').html(printFunktionen)
    }


  }
}


$('#bc2').on('click', function () {
  resultFunktionen.bauform == '';
})


function resetDevice() {
  resultFunktionen.bauform = '';
  $('.choose').removeClass('active')

  if (resultFunktionen.bauform == '') {
    $('#bc3').removeClass('activated')
    $('#bc4').removeClass('activated')
    $('#nextFunktion.nxtBtn').removeClass('active')
  }

}

function resetFunktionen() {

  resultFunktionen = {
    versorgung: '',
    anpassbereich: '',
    batterie: '',
    spatialSound: false,
    osn: false,
    tinnitus: false,
    bedienung: '',
    ghz: false,
    spule: false,
    autophone: false,
    earSize: '',
    bauform: ''

  };
  $('input[type="radio"]')[0].checked = false;
  breadcrumbLink.removeClass('active').removeClass('activated');
  $('#bc1').addClass('active').addClass('activated')
}