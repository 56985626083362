const typesFunktion = [
  {

    // IIC 75

    'bauform': 'IIC',
    'image': 'iic_1-2.png',
    'anpassbereich': '75',
    'bedienelemente': false,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '10er Batterie'],
    'sprite': '01_sprite.jpg',
    'class': 'IIC',

  },


  // IIC 85
  {
    'bauform': 'IIC',
    'image': 'iic_1-2.png',
    'anpassbereich': '85',
    'bedienelemente': false,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie', '10er Batterie'],
    'sprite': '02_sprite.jpg',
    'class': 'IIC',

  },


  // CIC 75

  {
    'bauform': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '75',

    'bedienelemente': false,

    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie', '10er Batterie'],
    'sprite': '03_sprite.jpg',
    'class': 'CIC',

  },
  {
    'bauform': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '75',

    'bedienelemente': true,

    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie', '10er Batterie'],
    'sprite': '03_sprite.jpg',
    'class': 'CIC',

  },
  {
    'bauform': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '75',
    'bedienelemente': true,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie', '10er Batterie'],
    'sprite': '03_sprite.jpg',
    'class': 'CIC',

  },
  {
    'bauform': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '75',
    'bedienelemente': false,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie', '10er Batterie'],
    'sprite': '03_sprite.jpg',
    'class': 'CIC',

  },



  // CIC 85
  {
    'bauform': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '85',

    'bedienelemente': false,

    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '10er Batterie'],
    'sprite': '04_sprite.jpg',
    'class': 'CIC',

  },
  {
    'bauform': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '85',

    'bedienelemente': true,

    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '10er Batterie'],
    'sprite': '04_sprite.jpg',
    'class': 'CIC',

  },
  {
    'bauform': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '85',
    
    'bedienelemente': true,

    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '10er Batterie'],
    'sprite': '04_sprite.jpg',
    'class': 'CIC',

  },
  {
    'bauform': 'CIC',
    'image': 'cic_3-4.png',
    'anpassbereich': '85',
    
    'bedienelemente': false,

    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '10er Batterie'],
    'sprite': '04_sprite.jpg',
    'class': 'CIC',

  },

  // Kanal 75

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_5-8.png',
    'anpassbereich': '75',

    'bedienelemente': false,

    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie'],
    'sprite': '05_sprite.jpg',
    'class': 'Kanal',
  },


  // Kanal 85
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_5-8.png',
    'anpassbereich': '85',
    'bedienelemente': false,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie'],
    'sprite': '06_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 90
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_5-8.png',
    'anpassbereich': '90',
    'bedienelemente': false,
    
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie'],
    'sprite': '07_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 100
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_5-8.png',
    'anpassbereich': '100',
    
    'bedienelemente': false,
    
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie'],
    'sprite': '08_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 75 GHZ

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '75',
    
    'bedienelemente': false,
    
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '09_sprite.jpg',
    'class': 'Kanal',

  },



  // Kanal 85 GHZ
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '85',
    
    'bedienelemente': false,
    
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '10_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 90 GHZ
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '90',
    
    'bedienelemente': false,
    
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '11_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 100 GHZ
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '100',
    
    'bedienelemente': false,
    
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '12_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 75 GHZ bedienung
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '75',
    
    'bedienelemente': true,
    
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '13_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 85 GHZ bedienung
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '85',
    
    'bedienelemente': true,
    
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '14_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 90 GHZ bedienung
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '90',
    
    'bedienelemente': true,
    
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '15_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 100 GHZ bedienung
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '100',
    
    'bedienelemente': true,
    
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '16_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 75 Spule bedienung
  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '75',
    
    'bedienelemente': true,
    
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'T-Spule'],
    'sprite': '13_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 85 Spule bedienung

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '85',
    
    'bedienelemente': true,
    
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'T-Spule'],
    'sprite': '14_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 90 Spule bedienung

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '90',
    
    'bedienelemente': true,
    
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'T-Spule'],
    'sprite': '15_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 100 Spule bedienung

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '100',
    
    'bedienelemente': true,
    
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'T-Spule'],
    'sprite': '16_sprite.jpg',
    'class': 'Kanal',

  },


  // Kanal 75 Spule 

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '75',
    
    'bedienelemente': false,
    
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'T-Spule'],
    'sprite': '09_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 85 Spule 

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '85',
    
    'bedienelemente': false,
    
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'T-Spule'],
    'sprite': '10_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 90 Spule 

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '90',
    
    'bedienelemente': false,
    
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'T-Spule'],
    'sprite': '11_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 100 Spule 

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_9-12.png',
    'anpassbereich': '100',
    
    'bedienelemente': false,
    
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'T-Spule'],
    'sprite': '12_sprite.jpg',
    'class': 'Kanal',

  },
  

  // Kanal 75 bedienung 

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '75',
    'bedienelemente': true,  
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie'],
    'sprite': '13_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 85 bedienung 

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '85',
    
    'bedienelemente': true,
    
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie'],
    'sprite': '14_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 90 bedienung 

  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '90',
    'bedienelemente': true,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie'],
    'sprite': '15_sprite.jpg',
    'class': 'Kanal',

  },

  // Kanal 100 bedienung 


  {
    'bauform': 'Kanal / Halb-Concha',
    'image': 'kanal_13-16.png',
    'anpassbereich': '100',
    
    'bedienelemente': true,
    
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '312er Batterie', 'Gehörgang: eher groß'],
    'sprite': '16_sprite.jpg',
    'class': 'Kanal',

  },


  // Concha 85 bedien ghz

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',  
    'bedienelemente': true,
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '13er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '17_sprite.jpg',
    'class': 'Concha',

  },

  // Concha 100 bedien ghz


  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100',
    'bedienelemente': true,
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '13er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '18_sprite.jpg',
    'class': 'Concha',

  },
  // Concha 85  ghz

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',  
    'bedienelemente': false,
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '13er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '17_sprite.jpg',
    'class': 'Concha',

  },

  // Concha 100  ghz


  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100',
    'bedienelemente': false,
    'ghz': true,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '13er Batterie', '2,4 GHz Bluetooth Low Energy'],
    'sprite': '18_sprite.jpg',
    'class': 'Concha',

  },
  // Concha 85

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',  
    'bedienelemente': false,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '13er Batterie'],
    'sprite': '17_sprite.jpg',
    'class': 'Concha',

  },

  // Concha 100


  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100',
    'bedienelemente': false,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '13er Batterie'],
    'sprite': '18_sprite.jpg',
    'class': 'Concha',

  },
  // Concha 85 bedien 

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',
    'bedienelemente': true, 
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '13er Batterie'],
    'sprite': '17_sprite.jpg',
    'class': 'Concha',

  },

  // Concha 100 bedien 


  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100',
    'bedienelemente': true,
    'ghz': false,
    'spule': false,
    'baseConfig': ['Opn Technologie',  '13er Batterie'],
    'sprite': '18_sprite.jpg',
    'class': 'Concha',

  },
  // Concha 85 bedien spule

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',
    'bedienelemente': true, 
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '13er Batterie', 'T-Spule'],
    'sprite': '17_sprite.jpg',
    'class': 'Concha',

  },

  // Concha 100 bedien spule

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100',
    'bedienelemente': true,
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '13er Batterie', 'T-Spule'],
    'sprite': '18_sprite.jpg',
    'class': 'Concha',

  },
  // Concha 85 spule

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',
    'bedienelemente': false, 
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '13er Batterie', 'T-Spule'],
    'sprite': '17_sprite.jpg',
    'class': 'Concha',

  },

  // Concha 100 spule

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100',
    'bedienelemente': false,
    'ghz': false,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '13er Batterie', 'T-Spule'],
    'sprite': '18_sprite.jpg',
    'class': 'Concha',

  },

  // Concha T-Spule 85 bedien ghz
  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',
    'bedienelemente': true,
    'ghz': true,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '13er Batterie', '2,4 GHz Bluetooth Low Energy',  'T-Spule'],
    'sprite': '19_sprite.jpg',
    'class': 'Concha',

  },



  // Concha T-Spule 100 bedien ghz

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100', 
    'bedienelemente': true,
    'ghz': true,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '13er Batterie', '2,4 GHz Bluetooth Low Energy',  'T-Spule'],
    'sprite': '20_sprite.jpg',
    'class': 'Concha',

  },
  // Concha T-Spule 85  ghz
  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '85',
    'bedienelemente': false,
    'ghz': true,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '13er Batterie', '2,4 GHz Bluetooth Low Energy',  'T-Spule'],
    'sprite': '19_sprite.jpg',
    'class': 'Concha',

  },



  // Concha T-Spule 100  ghz

  {
    'bauform': 'Concha',
    'image': 'ITC_Concha.png',
    'anpassbereich': '100', 
    'bedienelemente': false,
    'ghz': true,
    'spule': true,
    'baseConfig': ['Opn Technologie',  '13er Batterie', '2,4 GHz Bluetooth Low Energy',  'T-Spule'],
    'sprite': '20_sprite.jpg',
    'class': 'Concha',

  },

];
let resultFunktionen = {
  versorgung: '',
  anpassbereich: '',
  batterie: '',
  spatialSound: false,
  osn: false,
  tinnitus: false,
  bedienung: '',
  ghz: false,
  spule: false,
  autophone: false,
  earSize: '',
  bauform: '',
  bedienelemente: false,
  earValue: ''
};







