$(window).scroll(function () {
  const wScroll = $(this).scrollTop();
  $('.modusFunktionen .preview').css('top', wScroll + 'px');
  $('#breadcrumb').css('top', wScroll + 90 + 'px');
  $('.configHeader').css('top', wScroll + 'px');

  const previewScroll = $('.modusFunktionen .preview').offset().top;


})
