// Service Worker registrieren

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./sw.js').then(function () {
    console.log("Service Worker Registered");
  });
}



const info = $('#mainInfo');
const popupInfo = $('#popupInfo');
const close = $('.close');
const popup = $('.popup');


function closePopup() {
  popup.removeClass('active');
}

$('#minimize').on('click', function(){
 $('.configurator .preview').toggleClass('narrow');
})
$('.previewHeading').on('click', function(){

  $('.configurator .preview').toggleClass('narrow');

})





